<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("gradeBook.title") }} - {{title}}
          </template>
          <template v-slot:toolbar>
            <b-form-datepicker 
                id="example-datepicker" 
                v-model="date" 
                class="mb-2" 
                @context="changeDate" 
                locale="ru"
                start-weekday="1"
                :date-disabled-fn="dateDisabled"
            >
            </b-form-datepicker>
          </template>
          <template v-slot:body>
            <b-card no-body>
              <b-tabs pills card v-model="marksTabIndex" id="marksTabs">
                <b-tab v-for="(item, index) in days"
                       :key="item.date">
                  <template v-slot:title>
                    {{$t("studentTimeTable.day" + item.dayOfWeek)}}
                    <br>
                    {{ item.dateString }}
                  </template>
                  <b-card-title>{{$t("studentTimeTable.day" + (index +1))}}</b-card-title>
                  <b-card-body>
                    <div style="overflow: auto">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                        <tr>
                          <th width="5%" rowspan="2">#</th>
                          <th width="20%" rowspan="2" style="vertical-align: top">Ученики</th>
                          <th width="10%" :colspan="item.lessons.length">Предметы</th>
                        </tr>
                        <tr>
                          <th v-for="(lesson, lessonIndex) in item.lessons" :key="lessonIndex">
                            {{lesson.subjectName}} {{lesson.subgroupName}}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(student, k) in students" :key="k">
                          <td>{{k + 1}}</td>
                          <td>{{student.studentName}}</td>
                          <td v-for="(lesson, lessonIndex) in item.lessons" :key="lessonIndex">
                            <b-form-group id="input-group-x" label-for="input-2" v-if="showMark(student.studentId, lesson)">
                              <b-form-select
                                  id="input-x"                                  
                                  v-model="
                              marks[student.studentId][
                                lesson.id
                              ]
                            "
                                  :options="marksList"
                                  v-on:change="
                              changeMark(
                                student.studentId,
                                lesson.subjectId,
                                lesson.id,
                                lesson.subjectNumber,
                                $event
                              )
                            "></b-form-select>
                            </b-form-group>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-body>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  .nav-tabs{
    display: none;
  }

/*  table, th, td {
    border: 1px solid black;
  }*/
  .hw-title{
    font-style: italic;
  }

  .mobile-day-select{
    display: none;
  }
  @media (max-width: 767px) {

    #marksTabs div.col-auto{
      display: none;
    }

    .mobile-day-select{
      display: block;
    }
  }
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";


export default {
  name: "groupGradeBook",
  components: {
    KTPortlet
  },
  data() {
    return {
      //tab
      title: '',
      marksTabIndex: 0,
      daysIndexes: [],
      date: new Date(),
      days: {},
      students:[],
      lessonsByDay:{},
      marksList: [
        { value: 0, text: "" },
        { value: 1, text: "н" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" }
      ],
      marks: {}
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    reloadData(){
      let $this = this;
      let date = this.date;
      let params = {
        date : date,
        groupId: this.$route.params.groupId
      }
      ApiService.postSecured("marks/getGradeBook", params)  
          .then(({ data }) => {
            //$this.days = data.marks.markDays;
            $this.title = data.groupName;
            $this.students = data.students;
            $this.days = data.items;
            $this.marks = {};
            
            for (let i in data.students) {
              let student = data.students[i];

              $this.marks[student.studentId] = {};
            }

            for (let i in data.items) {
              let item = data.items[i];

              for (let ii in item.lessons){
                let lesson  = item.lessons[ii];
                for (let iii in lesson.marks){
                  let mark = lesson.marks[iii];
                  $this.marks[mark.studentId][mark.lessonId] = mark.mark;
                }
              }              
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    changeDate(){
      this.reloadData()
    },
    showMark(studentId, lesson){
      if(lesson.studentIds.length > 0){
        if(lesson.studentIds.includes(studentId)){
          return true;
        }
        
        return false;
      }
      
      return true;
    },
    changeMark(studentId, subjectId, lessonId, subjectNumber, event) {
      let markVal = event; /*.target.value*/

      let mark = {
        subjectId: subjectId,
        studentId: studentId,
        lessonId: lessonId,
        mark: markVal,
      };

      ApiService.postSecured("marks/save", mark)
          .then(function() {
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
  }
};
</script>
